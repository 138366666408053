type Props = {
  className: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
};

export function MultiLineTextInput({
  className,
  value,
  onChange,
  placeholder,
}: Props) {
  return (
    // eslint-disable-next-line react/forbid-elements
    <textarea
      className={className}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
    />
  );
}
