/**
 * @generated SignedSource<<d243bd0836733edf1dd718afeafcfbfa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TokenAmountDisplayFragment$data = {
  readonly amount: string;
  readonly currency: string;
  readonly decimals: number;
  readonly " $fragmentType": "TokenAmountDisplayFragment";
};
export type TokenAmountDisplayFragment$key = {
  readonly " $data"?: TokenAmountDisplayFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TokenAmountDisplayFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TokenAmountDisplayFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "decimals",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    }
  ],
  "type": "TokenAmount",
  "abstractKey": null
};

(node as any).hash = "c70646858e01e3c92b78274ce7b803b8";

export default node;
