/**
 * @generated SignedSource<<6fea6f900c5ebecbb478ac2ae5da5334>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VoterPanelFragment$data = {
  readonly delegateMetrics: {
    readonly " $fragmentSpreads": FragmentRefs<"DelegateFromListRowFragment" | "ProposalsCreatedRowFragment" | "RecentActivityRowFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"DelegateProfileImageFragment" | "DelegatingToRowFragment" | "ForAgainstAbstainRowFragment" | "ProposalsVotedRowFragment" | "VotePowerRowFragment" | "VoterPanelActionsFragment">;
  readonly " $fragmentType": "VoterPanelFragment";
};
export type VoterPanelFragment$key = {
  readonly " $data"?: VoterPanelFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"VoterPanelFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VoterPanelFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DelegateMetrics",
      "kind": "LinkedField",
      "name": "delegateMetrics",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DelegateFromListRowFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProposalsCreatedRowFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RecentActivityRowFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ForAgainstAbstainRowFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VotePowerRowFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProposalsVotedRowFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DelegateProfileImageFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VoterPanelActionsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DelegatingToRowFragment"
    }
  ],
  "type": "Delegate",
  "abstractKey": null
};

(node as any).hash = "4c4b1af26fbace07fa7c5124b4cc2930";

export default node;
