import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { PanelRow } from "./PanelRow";
import { VotePowerRowFragment$key } from "./__generated__/VotePowerRowFragment.graphql";
import { bpsToString } from "../../../utils/bps";

export function VotePowerRow({
  fragment,
}: {
  fragment: VotePowerRowFragment$key;
}) {
  const { tokensRepresentedVote } = useFragment(
    graphql`
      fragment VotePowerRowFragment on Delegate {
        tokensRepresentedVote {
          bpsOfTotal
          bpsOfStakedSupply
        }
      }
    `,
    fragment
  );

  return (
    <PanelRow
      title="Vote Power"
      detail={
        !tokensRepresentedVote
          ? "N/A"
          : `${bpsToString(
              tokensRepresentedVote.bpsOfTotal
            )} Lyra / ${bpsToString(
              tokensRepresentedVote.bpsOfStakedSupply
            )} stkLyra`
      }
    />
  );
}
