import { makeContractInstance } from "../hooks/useContractWrite";
import { LyraGovernor__factory } from "./generated";
import { StakedLyraToken__factory } from "./generated";

export const governanceTokenContract = makeContractInstance({
  factory: StakedLyraToken__factory,
  address: "0xCb9f85730f57732fc899fb158164b9Ed60c77D49",
  startingBlock: 16923920,
});

export const governorTokenContract = makeContractInstance({
  factory: LyraGovernor__factory,
  address: "0xe8642cc1249F08756e70Bb8eb4BE0e6c09254fed",
  startingBlock: 16408825,
});
