/**
 * @generated SignedSource<<280f1d9d4bc433048bfd7f0b6d66aadd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VotePowerRowFragment$data = {
  readonly tokensRepresentedVote: {
    readonly bpsOfStakedSupply: number;
    readonly bpsOfTotal: number;
  };
  readonly " $fragmentType": "VotePowerRowFragment";
};
export type VotePowerRowFragment$key = {
  readonly " $data"?: VotePowerRowFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"VotePowerRowFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VotePowerRowFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "VotingPower",
      "kind": "LinkedField",
      "name": "tokensRepresentedVote",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bpsOfTotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bpsOfStakedSupply",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Delegate",
  "abstractKey": null
};

(node as any).hash = "4bcdbc32f2aa1ff1e1d7b8fedba229c9";

export default node;
