/**
 * @generated SignedSource<<8b877ffe941e504d2c8ef5d623abed36>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProposalsCreatedRowFragment$data = {
  readonly proposalsCreated: number;
  readonly " $fragmentType": "ProposalsCreatedRowFragment";
};
export type ProposalsCreatedRowFragment$key = {
  readonly " $data"?: ProposalsCreatedRowFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProposalsCreatedRowFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProposalsCreatedRowFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "proposalsCreated",
      "storageKey": null
    }
  ],
  "type": "DelegateMetrics",
  "abstractKey": null
};

(node as any).hash = "7e38c4f78c76301ad5d17e13f439fdbe";

export default node;
