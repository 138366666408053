/**
 * @generated SignedSource<<c3cd750d5243c23d12fd99d71bad5ead>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SingatureType = "CONTRACT" | "EOA" | "%future added value";
export type CreateNewDelegateStatementData = {
  email?: ValueWithSignature | null;
  statement: ValueWithSignature;
};
export type ValueWithSignature = {
  signature: string;
  signatureType: SingatureType;
  signerAddress: string;
  value: string;
};
export type DelegateStatementFormMutation$variables = {
  input: CreateNewDelegateStatementData;
};
export type DelegateStatementFormMutation$data = {
  readonly createNewDelegateStatement: {
    readonly statement: {
      readonly discord: string;
      readonly statement: string;
      readonly topIssues: ReadonlyArray<{
        readonly type: string;
        readonly value: string;
      }>;
      readonly topStakeholders: ReadonlyArray<{
        readonly type: string;
        readonly value: string;
      }>;
      readonly twitter: string;
    } | null;
  };
};
export type DelegateStatementFormMutation = {
  response: DelegateStatementFormMutation$data;
  variables: DelegateStatementFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "data",
    "variableName": "input"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "DelegateStatement",
  "kind": "LinkedField",
  "name": "statement",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "discord",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "twitter",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TopIssue",
      "kind": "LinkedField",
      "name": "topIssues",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TopStakeholder",
      "kind": "LinkedField",
      "name": "topStakeholders",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DelegateStatementFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Delegate",
        "kind": "LinkedField",
        "name": "createNewDelegateStatement",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DelegateStatementFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Delegate",
        "kind": "LinkedField",
        "name": "createNewDelegateStatement",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4bd65cfd08e002b4061e084b9d126a33",
    "id": null,
    "metadata": {},
    "name": "DelegateStatementFormMutation",
    "operationKind": "mutation",
    "text": "mutation DelegateStatementFormMutation(\n  $input: CreateNewDelegateStatementData!\n) {\n  createNewDelegateStatement(data: $input) {\n    statement {\n      statement\n      discord\n      twitter\n      topIssues {\n        type\n        value\n      }\n      topStakeholders {\n        type\n        value\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8cc9fab628ca776298dfccc8631793d0";

export default node;
