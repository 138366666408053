import graphql from "babel-plugin-relay/macro";
import {
  Location,
  RouteLoadingParams,
} from "../../components/HammockRouter/HammockRouter";
import React from "react";
import {
  DelegatesOrder,
  HomePageRouteQuery,
  HomePageRouteQuery$variables,
  StatementFilter,
  TopIssuesFilter,
  TopStakeholdersFilter,
} from "./__generated__/HomePageRouteQuery.graphql";
import { issueDefinitions } from "../EditDelegatePage/TopIssuesFormSection";
import { topStakeholdersDefinitions } from "../EditDelegatePage/TopStakeholdersFormSection";
import { statementFilterNames } from "./DelegatesContainer";

/* eslint-disable relay/unused-fields, relay/must-colocate-fragment-spreads */
export const query = graphql`
  query HomePageRouteQuery(
    $orderBy: DelegatesOrder!
    $seed: String!
    $topStakeholders: TopStakeholdersFilter
    $topIssues: TopIssuesFilter
    $statement: StatementFilter
  ) {
    ...DelegatesContainerFragment
      @arguments(
        orderBy: $orderBy
        seed: $seed
        topStakeholders: $topStakeholders
        topIssues: $topIssues
        statement: $statement
      )

    ...OverviewMetricsContainerFragment
  }
`;

export type Variables = ReturnType<typeof locationToVariables>;

function locationToVariables(location: Location): HomePageRouteQuery$variables {
  return {
    orderBy: parseOrderName(location.search["orderBy"]) ?? "mostVotingPower",
    seed: Date.now().toString(),
    topIssues: parseTopIssue(location.search["topIssues"]),
    topStakeholders: parseTopStakeholder(location.search["topStakeholders"]),
    statement: parseStatement(location.search["statement"]),
  };
}

export const orderNames: { [K in DelegatesOrder]?: string } = {
  mostVotingPower: "Most voting power",
  mostDelegates: "Most delegates",
  mostVotesMostPower: "Most votes",
  random: "Random",
};

function parseOrderName(orderName: string): DelegatesOrder | null {
  if (orderName in orderNames) {
    return orderName as any;
  }

  return null;
}

function parseTopIssue(topIssue: string): TopIssuesFilter | null {
  if (issueDefinitions.findIndex((issue) => issue.key === topIssue) === -1) {
    return null;
  }

  return topIssue as TopIssuesFilter;
}

function parseTopStakeholder(
  topStakeholder: string
): TopStakeholdersFilter | null {
  if (
    topStakeholdersDefinitions.findIndex(
      (def) => def.key === topStakeholder
    ) === -1
  ) {
    return null;
  }

  return topStakeholder as TopStakeholdersFilter;
}

// If there is no statement filter, we don't want to return null
// we want to return the withStatement filter to only show the
// delegates with a statement as a default
function parseStatement(statement: string): StatementFilter | null {
  if (
    statementFilterNames.findIndex((item) => item.value === statement) === -1
  ) {
    return "withStatement";
  }

  return statement as StatementFilter;
}

export const homeRoute: RouteLoadingParams<HomePageRouteQuery> = {
  query,
  element: React.lazy(() => import("./HomePage")),
  variablesFromLocation(location) {
    return locationToVariables(location);
  },
};
