type Props = {
  className?: string;
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
};

export function TextInput({ className, value, onChange, placeholder }: Props) {
  return (
    // eslint-disable-next-line react/forbid-elements
    <input
      className={className}
      type="text"
      value={value}
      placeholder={placeholder}
      onChange={(event) => onChange(event.target.value)}
    />
  );
}
