import { useCallback } from "react";
import { css } from "@emotion/css";
import * as theme from "../../theme";
import { CloseButton } from "./CloseButton";
import { Form } from "./DelegateStatementForm";
import { HStack, VStack } from "../../components/VStack";
import { Menu } from "@headlessui/react";
import {
  dropdownContainerStyles,
  dropdownItemActiveStyle,
  DropdownItems,
  dropdownItemStyle,
  formSectionContainerStyles,
} from "./TopIssuesFormSection";
import {
  formSectionDescriptionStyle,
  formSectionHeadingStyle,
} from "../../utils/formSectionStyling";

type StakeholderTypeDefinition = {
  key: string;
  title: string;
};

export const topStakeholdersDefinitions: StakeholderTypeDefinition[] = [
  {
    title: "Builder",
    key: "builder",
  },
  {
    title: "Community member",
    key: "communityMember",
  },
  {
    title: "Liquidity provider",
    key: "liquidityProvider",
  },
  {
    title: "Trader",
    key: "trader",
  },
];

export type StakeholderState = {
  type: string;
  value: string;
};

function initialStakeholderState(type: string): StakeholderState {
  return {
    type,
    value: "",
  };
}

type Props = {
  form: Form;
};

export function TopStakeholdersFormSection({ form }: Props) {
  const topStakeholders = form.state.topStakeholders;
  const setTopStakeholders = form.onChange.topStakeholders;

  const addStakeholder = useCallback(
    (selectionKey: string) => {
      setTopStakeholders((lastStakeholders) => {
        return [...lastStakeholders, initialStakeholderState(selectionKey)];
      });
    },
    [setTopStakeholders]
  );

  const removeStakeholder = useCallback(
    (index: number) => {
      setTopStakeholders((lastStakeholders) =>
        lastStakeholders.filter((needle, needleIndex) => needleIndex !== index)
      );
    },
    [setTopStakeholders]
  );

  return (
    <div className={formSectionContainerStyles}>
      <HStack
        gap="4"
        justifyContent="space-between"
        alignItems="baseline"
        className={css`
          @media (max-width: ${theme.maxWidth.lg}) {
            flex-direction: column;
          }
        `}
      >
        <h3 className={formSectionHeadingStyle}>Stakeholder I represent</h3>
        <Dropdown
          topStakeholders={topStakeholders}
          addStakeholder={addStakeholder}
        />
      </HStack>
      <p className={formSectionDescriptionStyle}>
        Use this tag to identify what stakeholder group you are, or look to
        represent in your votes.
      </p>

      <VStack
        gap="4"
        className={css`
          margin-top: ${theme.spacing["6"]};
        `}
      >
        {!topStakeholders || !topStakeholders.length ? (
          <HStack gap="4" alignItems="center">
            <VStack
              className={css`
                flex: 1;
                position: relative;
              `}
            >
              <div
                className={css`
                  color: ${theme.colors.gray["700"]};
                `}
              >
                {" "}
              </div>
            </VStack>
          </HStack>
        ) : (
          topStakeholders.map((stakeholder, index) => {
            const label = topStakeholdersDefinitions.find(
              (definition) => definition.key === stakeholder.type
            )!.title;

            return (
              <HStack gap="4" alignItems="center" key={index}>
                <VStack
                  className={css`
                    flex: 1;
                    position: relative;
                  `}
                >
                  <VStack
                    className={css`
                      position: absolute;
                      right: 0;
                      top: 0;
                      bottom: 0;
                    `}
                  >
                    <CloseButton onClick={() => removeStakeholder(index)} />
                  </VStack>

                  <HStack
                    className={css`
                      border: 1px solid ${theme.colors.gray["300"]};
                      padding: ${theme.spacing["3"]};
                      border-radius: ${theme.borderRadius["md"]};
                    `}
                  >
                    I represent {label.toLowerCase()}s
                  </HStack>
                </VStack>
              </HStack>
            );
          })
        )}
      </VStack>
    </div>
  );
}

type DropdownProps = {
  addStakeholder: (key: string) => void;
  topStakeholders: StakeholderState[];
};

function Dropdown({ addStakeholder, topStakeholders }: DropdownProps) {
  const unsetStakeholderDefinitions = topStakeholdersDefinitions.filter(
    (definition) => !topStakeholders?.find((s) => s.type === definition.key)
  );

  return unsetStakeholderDefinitions.length ? (
    <Menu
      as="div"
      className={css`
        ${dropdownContainerStyles};
      `}
    >
      {({ open }) => (
        <>
          <Menu.Button
            className={css`
              color: #66676b;
              ${topStakeholders.length >= 1
                ? css`
                    cursor: not-allowed;
                    color: #d1d1d1; // todo: @yitong check disabled color
                  `
                : ""}
            `}
          >
            + Select stakeholder type
          </Menu.Button>
          <Menu.Items static>
            <DropdownItems open={topStakeholders.length === 0 && open}>
              {unsetStakeholderDefinitions.map((def) => (
                <Menu.Item key={def.key}>
                  {({ active }) => (
                    <div
                      onClick={() => addStakeholder(def.key)}
                      className={css`
                        ${dropdownItemStyle};
                        ${active && dropdownItemActiveStyle}
                      `}
                    >
                      {def.title}
                    </div>
                  )}
                </Menu.Item>
              ))}
            </DropdownItems>
          </Menu.Items>
        </>
      )}
    </Menu>
  ) : null;
}
