import { css } from "@emotion/css";

export const formSectionHeadingStyle = css`
  font-weight: bold;
`;

export const formSectionDescriptionStyle = css`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #4a5568;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;
